import axios from 'axios';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators'

export const rrsmService = {
    login,
    start,
    deleteContainer,
    restartWatchdog
}

function login() {
    return Observable.create((observer) => {
        axios.post('/api/rrsm/service/login')
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if(response.success !== true) {
                throw response.message;
            }
            saveRRSMtoken(response.token)
        })
    )
}

function start(_token) {
    return Observable.create((observer) => {
        axios.post('/api/rrsm/service/start', { token: _token })
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if(response.success !== true) {
                throw response.message;
            }
            saveRRSMsid(response.data.sid, response.data.urPort, response.data.rosPort)
        })
    )
}

function deleteContainer(_token, _sid) {
    return Observable.create((observer) => {
      axios.post('/api/rrsm/service/delete',
      {
          token: _token,
          sid: _sid
      })
      .then(response => {
          observer.next(response.data)
          observer.complete()
      })
      .catch(err => {
          observer.error(err)
      })
    })
    .pipe(
        map(data => data.message)
    )
}

function restartWatchdog(_token, _sid) {
    return Observable.create((observer) => {
      axios.post('/api/rrsm/watchdog/restart',
      {
          token: _token,
          sid: _sid
      })
      .then(response => {
          observer.next(response.data)
          observer.complete()
      })
      .catch(err => {
          observer.error(err)
      })
    })
    .pipe(
        map(data => data.message)
    )
}

function saveRRSMtoken(_token) {
  localStorage.setItem('rrsmToken', JSON.stringify({
    token: _token
  }));
}

function saveRRSMsid(_sid, _urPort, _rosPort) {
  localStorage.setItem('rrsmSid', JSON.stringify({
    sid: _sid,
    urPort: _urPort,
    rosPort: _rosPort
  }));
}
